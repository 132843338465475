<template>
<div class="">
      <v-card max-width="600" >
      <v-card-title primary-title>
        {{titleText}}
      </v-card-title>
      <v-card-text>
        <v-layout class="py-3 px-2 full-width"  wrap>
          <v-flex xs12>
          </v-flex>
          <v-flex xs12>
            <v-textarea  class="full-width" label="Describe your issue" hint="Please be included all information you think might be relevant for  us to solve your issue" auto-grow v-model="desc"></v-textarea>

          </v-flex>
          <v-flex xs12>

          <v-text-field
            v-model="video"
            v-if="noVideo!=true"
            dont-fill-mask-blanks
            browser-autocomplete="disable"
            label="Add a link to a video"
            hint="Videos often give a better view of the situation. We strongly recommend attaching a video to this support request."
          ></v-text-field>
          </v-flex>
          <v-flex xs12>

            <Dropzone id="photos" v-if="noPhoto!=true" @onUpload="uploadPhotos" @onSorted="sortedPhotos" :photos="photos" landlordId="issues">
            </Dropzone>
          </v-flex>
          <v-btn color="primary" :disabled="disable" @click="sendIssue">Send</v-btn>
        </v-layout>
      </v-card-text>
     </v-card>
     <v-dialog
       v-model="confirmDialog"
       persistent :overlay="false"
       max-width="250px"
       transition="dialog-transition"
     >
     <v-card>
       <v-card-text>
         Issue submitted. You will receive an email with the summary. <br>We will get in touch soon
       </v-card-text>
       <v-card-actions>
         <v-spacer></v-spacer>
         <v-btn color="primary" flat small :href="'/myBooking'">Back to my booking</v-btn>
       </v-card-actions>
     </v-card>
     </v-dialog>
</div>
</template>
<script>
import Dropzone from '../../Dropzone.vue'
import axios from 'axios'
import {
  mapGetters,
} from 'vuex'
import AWSSES from '../../../aws-ses'

import * as types from '../../../store/types'
import moment from 'moment'

export default {
  name: "",
  props:['titleText','s1','s2','noVideo','noPhoto','textDefault','ll','user','cr'],
  data: () => ({
    desc:"",
    crID:"",
    photos:[],
    video:null,
    disable:false,
    confirmDialog:false,
  }),

  methods: {
    uploadPhotos(x) {
      // console.log('photos uploaded -->', x);
      this.photos.push(x);
    },

    sortedPhotos(x) {
      // console.log('photos uploaded -->', x);
      this.photos = x;
    },
    sendIssue(){
      this.disable=true
      let issue={
        s1:this.s1,
        s2:this.s2,
        desc:this.desc,
        crID:this.crID,
        ts:moment(),
        tenant:this.user.id,
        ll:this.ll.id
      }
      if (this.photos.length>0){
        issue.photos=this.photos
      }
      if (this.video!=null && this.video!=""){
        issue.video=this.video
      }
      axios.post('issues.json?auth='+ this.$store.state.auth.idToken,issue)
      .then(()=>{
        this.sendEmail()
        this.confirmDialog=true
        this.disable=false
        // alert("Issue submitted. You will receive an email with the summary. We will get in touch soon" )

      })
      .catch(err=>{
        console.log(err);
        this.disable=false
        alert("Something happened, please send an email to hello@myroomabroad.com")
      })
    },

    sendEmail(){
      let name=this.user.userName
      if (this.user.hasOwnProperty('name')){
        name=this.user.name
      }
      let admin={
        userName:'admin',
        email:'hello@myroomabroad.com'
      }
      let info={
        subject:this.subject,
        tenantName:name,
        issueText:this.desc,

      }
        if (this.s1==4){
          // problem with infrastructure so send to landlord also
          // console.log(this.ll, 'sendLL');
          AWSSES.sendIssue(this.ll, info, 'LLIssue')
        }
        // send to tenant
        // console.log(this.user, 'sendUser');
        AWSSES.sendIssue(this.user, info, 'TIssue')
        // send to admin
        AWSSES.sendIssue(admin, info,'AIssue')
    }
  },
  computed:{
    subject(){
      let t=this.titleText
      if(this.titleText.includes('submit an issue') || this.s2==0 ||this.s1==0){
        switch (this.s1) {
          case 1:
            switch (this.s2) {
              case 2:
                t="The room is dirty"
                break;
              case 3:
                t="Cash deposit"
                break;
              default:
                t="issue at move in"
            }
            break;
          case 4:
            console.log('do this');
            switch (this.s2) {
              case 1:
                t="Clogged pipes"
                break;
              default:
                t="Problem with infrastructure"
            }
            console.log('finished case 4', t);
            break;


          default:
            console.log('do general default');
            t="Other"
        }
      }
      return "ISSUE: "+t +': ' +this.user.userName+" in " +this.cr.fullRoomNumber
    }
  },
  created() {
    if(this.textDefault!=undefined){
      this.desc=this.textDefault
      this.crID=this.cr['.key']
    }
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  },
  components: {
    Dropzone
  }
}
</script>
<style lang="scss" scoped>
.full-width{
  width:100%;
}
</style>
