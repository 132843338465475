<template>
<div class="">
  <v-container grid-list-xs,sm,md,lg,xl>
    <v-layout space-around wrap>
      <v-btn color="primary" flat small @click="back" v-if="s1!= null">Back</v-btn>
      <!-- Select your initial topic group -->

      <v-card
         class="mx-auto"
         width="350" v-if="s1==null"
       >
      <v-card-title primary-title class="mx-5">
        What is your issue about?
      </v-card-title>

      <v-layout class="py-3 topic-option px-2" @click="s1=topic.val" v-for="(topic,i) in initial" :key="topic.name+' - '+i"  wrap>
      <v-flex>
          {{topic.name}}
        </v-flex>
      </v-layout>
     </v-card>
     <!-- Check in Issues -->
     <v-card
        class="mx-auto"
        width="350" v-if="s1==1 && s2==null"
      >
     <v-card-title primary-title class="mx-5">
       What is your issue about?
     </v-card-title>

     <v-layout class="py-3 topic-option px-2" @click="s2=topic.val" v-for="(topic,i) in checkIn" :key="topic.name+' - '+i" wrap>
     <v-flex>
         {{topic.name}}
       </v-flex>
     </v-layout>
     </v-card>
     <!-- room not as Described -->
     <PhotoIssueCard class="mx-auto" v-if="s1==1 &&s2==1"
      :s1="s1"
      :s2="s2"
      :noPhoto="false"
      :noVideo="false"
      textDefault="The room is not as described: I have added photos and a video below for you to see. Here's also a list of items which are different than on the listing:"
      titleText="My room is not as described"
      :ll="ll"
      :user="user"
      :cr="cr"
      ></PhotoIssueCard>
      <!-- Room dirty -->


      <!-- cleaned House -->
      <PhotoIssueCard class="mx-auto" v-if="s1==1 &&s2==2 && cleaned==true"
       :s1="s1"
       :s2="s2"
       :noPhoto="false"
       :noVideo="false"
       textDefault="My room was supposed to have professional cleaning before I arrived but it didn't. Please see attached the photos and video to prove my point."
       titleText="My room is dirty upon arrival"
       :ll="ll"
       :user="user"
       :cr="cr"
       ></PhotoIssueCard>
       <!-- Not Cleaned on arrival but Cleaning service -->
      <v-card class="mx-auto" v-else-if="s1==1 && s2==2 && cs==true && showAnyway!=true" width='350'>
        <v-card-title primary-title>
          Dirty room
        </v-card-title>
        <v-card-text>
          Cleaning service means the landlord will send someone to clean the common areas. You are always responsible for cleaning your own room.
          <br>
          Most of the time this is done every week or two weeks.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary">order cleaner</v-btn>
          <v-btn color="error" flat small @click="showAnyway=true&& showAnyway!=true"> I still want to submit an issue</v-btn>
        </v-card-actions>
      </v-card>
      <!-- No cleaning service whatsosever -->
      <v-card class="mx-auto" v-else-if="s1==1 && s2==2 && cs==false && cleaned==false && showAnyway!=true" width='400'>
        <v-card-title primary-title>
          Dirty room
        </v-card-title>
        <v-card-text>
          The landlord did not announce any cleaning service on the listing. Therefore it is up to you and the other tenants to clean the apartment.
          <br>
          It happens quite often that the room is not clean upon move in.
          <br>
          We recommend you take a few hours to thoroughly clean your room.
          Alternatively, you can order a professional cleaner. The price for a cleaner starts at 400TWD/hour.
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :href="'https://jackercleaning.com'" target="blank" >order cleaner</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" flat small @click="showAnyway=true">Submit an issue</v-btn>
        </v-card-actions>
      </v-card>
<!-- Pay Deposit in cash -->
      <v-card class="mx-auto" v-if="s1==1 && s2==3 && showAnyway!=true" width='400'>
        <v-card-title primary-title>
          Pay desposit in cash
        </v-card-title>
        <v-card-text>
          This is perfectly normal. As explained when you booked your room, you are supposed to pay your deposit when you move in.
          <br>
          <br>
          If you can't withdraw the amount today but still want to pay it in cash, the landlords are usually understanding about this and allow a few days for withdrawing it.
          <br>
          <br>
          If you prefer to pay by credit card, ask your landlord to enable it for you. They can do this in their tab "My Tenants"
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary":disabled="user.deposit==true" small target="blank" @click="showQR=true" >Ask your landlord</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" flat small @click="showAnyway=true">Submit an issue</v-btn>
        </v-card-actions>
      </v-card>
<!-- Contract issue -->
      <PhotoIssueCard class="mx-auto" v-if="s1==1 && s2==4"
       :s1="s1"
       :s2="s2"
       :noPhoto="false"
       :noVideo="true"
       textDefault="I have added scan of the contract in the photos. My issues with this contract are:"
       titleText="I have an issue with my contract"
       :ll="ll"
       :user="user"
       :cr="cr"
       ></PhotoIssueCard>
<!-- Payment issue -->
      <v-card class="mx-auto" max-width="400px" v-if="s1==2 && s2==null">
        <v-card-title primary-title>
          Payment issue
        </v-card-title>
        <v-card-text>
          Payment errors happen mainly for two reasons:
          <br>
          <br>
          1: You don't have enough money on your bank account or your limits are not high enough to process this transfer.
          <br>
          2: Your bank has declined the transfer. This happens often in the beginning as you are transferring large amounts to a new country in a not very used currency.
          <br><br>
          Please get in touch with your bank to solve the problem before filing an issue.
        </v-card-text>
        <v-card-actions>
          <v-btn color="" flat small :href="'/myBooking'">My Booking</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat small @click="s2=1">Submit issue</v-btn>
        </v-card-actions>
      </v-card>
      <PhotoIssueCard class="mx-auto" v-if="s1==2 && s2==1"
        :s1="s1"
        :s2="s2"
        :noPhoto="true"
        :noVideo="true"
        textDefault="I have called my bank, this is what they told me: "
        titleText="I have an issue with a payment"
        :ll="ll"
        :user="user"
        :cr="cr"
      ></PhotoIssueCard>
<!-- Issue with landlord -->
      <PhotoIssueCard class="mx-auto" v-if="s1==3 "
        :s1="s1"
        :s2="1"
        :noPhoto="true"
        :noVideo="true"
        textDefault=""
        titleText="I have an issue with my landlord"
        :ll="ll"
        :user="user"
        :cr="cr"
      ></PhotoIssueCard>
<!-- Infrastructure issue -->
      <v-card
         class="mx-auto"
         width="350" v-if="s1==4 && s2==null"
       >
      <v-card-title primary-title class="mx-5">
        Please select
      </v-card-title>
      <v-layout class="py-3 topic-option px-2" @click="s2=topic.val" v-for="(topic,i) in roomIssues" :key="topic.name+' - '+i" wrap>
      <v-flex>
          {{topic.name}}
        </v-flex>
      </v-layout>
      </v-card>
  <!-- Clogged -->
      <v-card class="mx-auto" max-width="400px" v-if="s1==4 && s2==1 && showAnyway!=true">
        <v-card-title primary-title>
          Something got clogged
        </v-card-title>
        <v-card-text>
          Clogged toilets and sinks suck. We know.
          <br>
          <br>
          Unclogging them is most of the time a super simple task. You'll be much quicker unclogging it yourself than waiting for a plumber to come and fix it.
          <br>
          All you need is a plunger and if that doesn't work, you can find products to unclog in most supermarkets (Welcome/PX mart or larger).
          <br>
          <br>
          If you have tried these solution, and still want your landlord to call a plumber, please submit an issue
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" flat small :href="'https://www.youtube.com/results?search_query=how+to+use+a+plunger'" target="blank">Learn how</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="error" flat small @click="showAnyway=true">Submit issue</v-btn>
        </v-card-actions>
      </v-card>

<!-- Leakage -->
      <PhotoIssueCard class="mx-auto" v-if="s1==4 && s2==2"
        :s1="s1"
        :s2="s2"
        :noPhoto="false"
        :noVideo="false"
        textDefault="There's a leakage. Please find attached photos and a video that show the issue. Could you send a plumber as soon as possible?"
        titleText="There's a leakage"
        :ll="ll"
        :user="user"
        :cr="cr"
      ></PhotoIssueCard>
<!-- I need a new Gas Bottle -->
      <PhotoIssueCard class="mx-auto" v-if="s1==4 && s2==3"
        :s1="s1"
        :s2="s2"
        :noPhoto="false"
        :noVideo="true"
        textDefault="The gas is empty so I need a new gas bottle. I added a picture of the gas bottle. This is the number written on it:                   I will be home at these times today to open the door to the delivery guy: "
        titleText="I need a new gas bottle"
        :ll="ll"
        :user="user"
        :cr="cr"
      ></PhotoIssueCard>
      <PhotoIssueCard class="mx-auto" v-if="s1==4 && s2==4"
        :s1="s1"
        :s2="s2"
        :noPhoto="true"
        :noVideo="true"
        textDefault="I lost my keys :( Can you please make me a new one? Thanks!"
        titleText="I lost my key"
        :ll="ll"
        :user="user"
        :cr="cr"
      ></PhotoIssueCard>


      <PhotoIssueCard class="mx-auto" v-if="showAnyway==true"
       :s1="s1"
       :s2="s2"
       :noPhoto="false"
       :noVideo="false"
       textDefault=""
       titleText="I want to submit an issue"
       :ll="ll"
       :user="user"
       :cr="cr"
       ></PhotoIssueCard>
       <PhotoIssueCard class="mx-auto" v-if="s1==0 || s2==0"
        :s1="s1"
        :s2="s2"
        :noPhoto="false"
        :noVideo="false"
        textDefault=""
        titleText="Please describe your issue"
        :ll="ll"
        :user="user"
        :cr="cr"
        ></PhotoIssueCard>
     <!-- <v-card
     class="mx-auto"
     width="300"

     >
     <v-card-title primary-title>
     </v-card-title>
     <v-card-text>
       Please make sure you have photos and a link to a video to everything that is not as described.
       <br>
       Here you can find a <a href="#">link to your room</a>
     </v-card-text>
   </v-card> -->

      <!-- Infrastructure issues-->

     <v-dialog
       v-model="showQR"
       scrollable
       persistent
       max-width="350px"
       transition="dialog-transition"
     >
     <v-card>
        <v-card-title primary-title>
          Scan this
        </v-card-title>
        <v-card-text>
          Ask your landlord to scan this QR Code to go to his 'My Tenants' page.
          <br>
          <br>
          <v-layout justify-center wrap>
            <qrCode small :text="'https://myroomabroad.com/tenants'"></qrCode>
            <v-flex xs8>

            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" flat @click="showQR=false">close</v-btn>
        </v-card-actions>
      </v-card>

     </v-dialog>

    </v-layout>
  </v-container>
</div>
</template>
<script>
import PhotoIssueCard from './issueCards/PhotoIssueCard.vue'
import axios from 'axios'
import VueQRCodeComponent from 'vue-qrcode-component'

import {
  mapGetters,
} from 'vuex'

import * as types from '../../store/types'
import moment from 'moment'


export default {
  name: "",
  data: () => ({
    cr:{},
    ll:{},
    listing:{},
    depositDialog:false,
    deposit:null,
    showAnyway:false,
    s1:null,
    s2:null,
    initial:[
      {
        name:"I have an issue at check in",
        val:1
      },
      {
        name:"I have an issue with a payment",
        val:2
      },
      {
        name:"I have an issue with my landlord",
        val:3
      },
      {
        name:"There is a problem with the infrastructure",
        val:4
      },
      {
        name:"Others",
        val:0
      }
    ],
    checkIn:[
      {
        name:"The room is not as described on the platform.",
        val:1
      },
      {
        name:"My room is dirty on arrival.",
        val:2
      },
      {
        name:"The landlord asks me to pay the deposit in cash.",
        val:3
      },
      {
        name:"There's an issue with my contract.",
        val:4
      },
      {
        name:"Other",
        val:0
      }
    ],
    roomIssues:[
      {
        name:"Something got clogged",
        val:1
      },
      {
        name:"There is a leakage",
        val:2
      },
      {
        name:"I need a new gas bottle",
        val:3
      },{
        name:"I lost my keys",
        val:4
      },
      {
        name:"Other",
        val:0
      },
    ],
    cleaned:false,
    crID:"",
    cs:false,
    showQR:false
  }),
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },


  methods: {
    back() {
      if (this.showAnyway==true){
        this.showAnyway=false
      } else if (this.s2!=null){
        this.s2=null

      } else {
        this.s1=null
      }
    },
    getLL(){
      axios.get('users/'+this.cr.landlordId+'.json')
      .then(res=>{
        this.ll=res.data

      })
    },
    getRoom(){
      switch (this.cr.type) {
        case 'room':
          axios.get('rooms/'+this.cr.listingId+'.json')
          .then(res=>{
            this.listing=res.data
            axios.get('apartments/'+this.listing.apartmentId+'.json')
            .then(res=>{
              this.listing.apt=res.data
              if (this.listing.apt.hasOwnProperty('featureList') && this.listing.apt.featureList.hasOwnProperty('cleaned')){
                this.cleaned=this.listing.apt.featureList.cleaned
                if (this.listing.apt.featureList.features.includes('cleaningService')){
                  this.cs=true
                }
              } else {
                this.cleaned=false
                this.cs=false
              }
            })
          })
          break;
        case 'studio':
          axios.get('studios/'+this.cr.listingId+'.json')
          .then(res=>{
            this.listing=res.data
            this.cleaned=this.listing.featureList.cleaned
            if (this.listing.featureList.features.includes('cleaningService')){
              this.cs=true
            }
          })
          break
        case 'apartment':
          axios.get('wholeApts/'+this.cr.listingId+'.json')
          .then(res=>{
            this.listing=res.data
          })
          break
        default:
          db=''

      }
    },
    getFirstId(obj){
      for (var id in obj) {
        if (obj.hasOwnProperty(id)) {
          this.cr=obj[id]
          this.cr['.key']=id
          this.getLL()
          this.getRoom()
          return id
        }
      }
    },
  },
  created() {
    if (this.user.hasOwnProperty('bookingRequestOut')){
      axios.get('completedOrders.json?orderBy="id"&equalTo="'+this.user.bookingRequestOut+'"')
      .then(res=>{
        console.log(res.data);
        this.crID=this.getFirstId(res.data)
      })
    }

    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  },
  components: {
    PhotoIssueCard,
    qrCode:VueQRCodeComponent

  },
}
</script>
<style lang="scss" scoped>
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
}
.fixedHeight{
  height:4em
}
.topic-option{
  border-top: solid thin #ccc;
}
.topic-option:hover{
  background-color: rgba(0, 191, 255, .1);
  cursor:pointer;

}
.blog-title {
    margin: auto;
    padding-top: 15vh;
    margin-bottom: 10vh;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}
.careers div {
    margin: 1rem 0;
}
</style>
