<template>
<div id="">
  <div class="">
    <Navbar></Navbar>
  </div>
  <v-toolbar dense dark color="primary">
      <v-toolbar-side-icon class="hidden-md-and-up" @click="drawer=!drawer"></v-toolbar-side-icon>
      <v-spacer></v-spacer>
      <v-toolbar-items>
          <v-list-tile dark class="hover" v-for="(item,index) in tabs" :key="index" :href="item.link">
            <v-list-tile-title>{{item.name}}</v-list-tile-title>
          </v-list-tile>

      </v-toolbar-items>
    </v-toolbar>
    <v-navigation-drawer  absolute temporary v-model="drawer">
      <v-toolbar color="primary" dark flat >
        <v-list>
          <v-list-tile>
            <v-list-tile-title class="title">
              Take your pick
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-toolbar>
      <v-divider></v-divider>
      <v-list dense class="pt-0">
        <v-list-tile v-for="(item,index) in tabs" :key="index" :href="item.link">
          <v-list-tile-title>{{item.name}}</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>

    <router-view class=""></router-view>

</div>
</template>
<script>
export default {
  name: "",
  data: () => ({
    drawer:false,
    tabs:[
      {
        name:"Home",
        link:"/mybooking"
      },
      {
        name:"Rent",
        link:"/mybooking/rent"
      },
      {
        name:"Issues",
        link:"/mybooking/issues"
      },
      {
        name:"Past payments",
        link:"/mybooking/payments"
      },

    ]
  }),
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

  }
}
</script>
<style lang="scss" scoped>
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
}
.fixedHeight{
  height:4em
}
.blog-title {
    margin: auto;
    padding-top: 15vh;
    margin-bottom: 10vh;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}
</style>
