<template>
<div id="">

  <v-container grid-list-xs>
    <v-layout  wrap justify-center>

      <v-flex xs11 sm8>
        <v-layout  wrap>
          <v-flex >

          <h2 class="display-1">{{$t("paymentPage.mra")}}</h2>
          <div class="official-info">
            <br>My Room Abroad - 出國租
            <br>VAT: 50895192
            <br>臺北市大同區南京西路163號2樓之39

          </div>
          <h1 class="ma-5 display-2">{{$t("paymentPage.invoice")}}</h1>
        </v-flex>

          <v-spacer></v-spacer>
          <v-img aspect-ratio=1 src="https://res.cloudinary.com/my-room-abroad/image/upload/q_auto,fl_lossy,f_auto/v1537411386/homepage/Logo_square.png" alt="My Room Abroad logo" height="100%"></v-img>
        </v-layout>
        <v-layout  wrap>

          <v-flex xs12 sm4>
            <h3>{{$t("paymentPage.invoiceFor")}}</h3>
            <p>{{invoice.tenant.name}}</p>
            <h3>{{$t("paymentPage.dueDate")}}:</h3>
            <p>{{invoice.dueDate | date}}</p>
          </v-flex>
          <v-flex xs12 sm6>
            <h3>{{$t("paymentPage.payableTo")}}</h3>
            <p>
              {{$t("paymentPage.mra")}}
            </p>
            <div class="account-details border">
              <p>
                <div class="font-weight-bold">
                  Show this to the bank clerk:
                  <br> 我要將錢存進帳戶

                </div>
                <br>{{$t("paymentPage.accountHolder")}}: 出國租股份有限公司
                <br>Bank: Bank of Taiwan
                <br>Bank Code: 004
                <br>Branch Code: 0196 (延平分行)
                <br>{{$t("paymentPage.accountNumber")}}: 019 001 163 903
              </p>
            </div>
            <p>Communication: {{invoice.invoiceNumber}}</p>

          </v-flex>
          <v-flex xs12 sm2>
            <h3>{{$t("paymentPage.invoice")}} #</h3>
            <p>{{invoice.invoiceNumber}}</p>

          </v-flex>

        </v-layout>
        <v-divider></v-divider>
        <v-layout  wrap class="ma-3 no-ma-sides">
          <v-flex xs9>
            <h2>{{$t("paymentPage.description")}}</h2>
            <br>
            <p>{{invoice.comment}}</p>
            <p v-if="parseInt(invoice.utilities)>0">{{$t("billing.utilities")}}</p>
            <p v-if="parseInt(invoice.utilities)<0">Discount</p>
            <p v-if="daysLate !=0">Late Fees: {{daysLate}} days (100 TWD/day)</p>
          </v-flex>
          <v-flex xs3 class="text-xs-right">
            <h2>{{$t("paymentPage.amount")}}</h2>
            <br>
            <p>{{invoice.price}} TWD</p>
            <p v-if="parseInt(invoice.utilities)!=0">{{invoice.utilities}} TWD</p>
            <p v-if="daysLate!=0">{{lateFees}} TWD</p>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout  wrap class="ma-3 no-ma-sides">
          <v-flex xs6 sm9 class='text-xs-right'>
            <h2>{{$t("paymentPage.totalToPay")}}</h2>
          </v-flex>
          <v-flex xs6 sm3 class='text-xs-right' >
            <h2>
              {{total}} TWD
            </h2>
          </v-flex>
        </v-layout>
        </v-flex>

      </v-layout>

  </v-container>
</div>
</template>
<script>
import moment from 'moment'
import Loading from '../shared/Loading.vue'

export default {
  name: "",
  props: ['invoice','user'],
  data: () => ({
  }),
  methods: {

  },
  computed:{
    total(){
      let add=this.lateFees+parseInt(this.invoice.utilities)
      if (this.invoice.nextRent!=undefined){
        return parseInt(this.invoice.nextRent)+ add
      } else {
        return parseInt(this.invoice.price) + add
      }
    },
    daysLate(){
      console.log('late fees: ',this.invoice.noLateFees, this.invoice);
      if (this.invoice && this.invoice.noLateFees!=true && moment(this.invoice.dueDate).isValid() && moment(this.invoice.dueDate).isBefore(moment())){
        return moment().diff(moment(this.invoice.dueDate),'days')
      } else {
        return 0
      }
    },
    lateFees(){
      return this.daysLate*100
    },

  },
  created() {
    //do something after creating vue instance
    console.log('do the created');


  },
  components:{
    Loading,

  },
  filters:{
    date(item){
      return moment(item).format('LL')
    }
  }
}
</script>
<style lang="scss" scoped>


</style>
