<template>
<div class="">

  <v-layout  wrap justify-space-around>
    <v-flex xs12 sm10 md8>

    <CCPaymentPage :invoice="invoice"></CCPaymentPage>


    </v-flex>
  </v-layout>

</div>
</template>
<script>
import {
  mapGetters,
} from 'vuex'
import TransferPage from './TransferPage.vue'
import CardPayment from '../shared/CardPayment.vue'
import CCPaymentPage from './CCPaymentPage.vue'
import axios from 'axios'
import * as types from '../../store/types'

export default {
  name: "",
  data: () => ({
    tUser:{},
    type:'',
    invoice:{},
  }),
  methods: {
    findInvoice() {
      if (this.tUser.unpaidInvoice || this.tUser.deposit){
        axios.get('/rents.json?orderBy="tenantId"&equalTo="'+this.tUser.id+'"')
        .then(res=>{
          console.log(res);
          for (var inv in res.data) {
            if (res.data.hasOwnProperty(inv) && res.data[inv].deposit==true) {
              this.invoice=res.data[inv]
              this.invoice['.key']=inv
            } else if (res.data.hasOwnProperty(inv) && res.data[inv].roomChange==true){
              this.invoice=res.data[inv]
              this.invoice['.key']=inv
            }
          }
          this.invoice.tenant=this.tUser
          console.log(this.invoice);
        })
      }
    },
    // getPrime() {
    //   TPDirect.card.getPrime((result) => {
    //     console.log(result.card.prime)
    //
    //     alert(result.card.prime);
    //   })
    // }
  },
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    if (this.$route.query.id&& this.user.roleValue>20){
      axios.get('/users/'+this.$route.query.id+'.json')
      .then(res=>{
        this.tUser=res.data
        this.tUser.id=this.$route.query.id
        this.findInvoice()
      })
    } else {
      this.tUser=this.user
      this.findInvoice()
    }



  },
  // mounted() {
  //   //do something after mounting vue instance
  //   console.log(TPDirect.card);
  //   TPDirect.card.setup('#tappay-iframe')
  // },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  components: {
    CardPayment,
    TransferPage,
    CCPaymentPage,
  },


}
</script>
<style lang="scss" scoped>
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
}
.fixedHeight{
  height:4em
}
.blog-title {
    margin: auto;
    padding-top: 15vh;
    margin-bottom: 10vh;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}
.careers div {
    margin: 1rem 0;
}
#tappay-iframe {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  line-height: 1.21428571em;
  padding: .578571em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, .15);
  color: rgba(0, 0, 0, .87);
  border-radius: .28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  -webkit-transition: color .1s ease, border-color .1s ease;
  transition: color .1s ease, border-color .1s ease;
  width: 100%;
}

</style>
