<template>
<div class="">
  <div class="" v-if="this.tUser.unpaidInvoice">
    <v-layout  wrap justify-center v-if="type==''">
      <v-flex xs12 text-xs-center class="headline pt-5">
        How would you like to pay your rent?
      </v-flex>
      <v-card hover height="300" width="250" class="ma-5" @click="type='CC'">
        <v-layout  align-center justify-center  wrap>
          <v-icon class="huge" large>credit_card</v-icon>

        </v-layout>
        <v-card-title class="text-xs-center fix-height" justify-center primary-title>
          <v-layout  justify-center  wrap>
            Credit Card
          </v-layout>
        </v-card-title>
        <v-card-actions align-baseline justify-center>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="type='CC'">Go</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-card hover height="300" width="250"  class="ma-5" @click="type='Transfer'">
        <v-layout  align-center justify-center  wrap>
          <v-icon class="huge" large>attach_money</v-icon>

        </v-layout>
        <v-card-title  class="fix-height text-xs-center " justify-center primary-title>
          <v-layout class=""  justify-center  wrap>
            Cash deposit / TWD Transfer
          </v-layout>
        </v-card-title>
        <v-card-actions justify-center>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="type='Transfer'">Go</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
<!--
        <v-btn color="primary" @click="type='CC'">Pay by Credit Card</v-btn>
        <v-btn color="primary" @click="type='Transfer'">Pay by Bank Transfer/deposit</v-btn> -->
    </v-layout>
    <div class="" v-else>
      <TransferPage v-if="type=='Transfer'" :invoice="invoice"></TransferPage>
      <v-layout  wrap v-else justify-space-around>
        <v-flex xs12 sm10 md8>

        <CCPaymentPage :invoice="invoice"></CCPaymentPage>


        </v-flex>
      </v-layout>
    </div>
  </div>
  <div class="" v-else>
    <v-layout  wrap justify-center>
      <h2 class="display-1">No rent to be paid</h2>
    </v-layout>
  </div>
</div>
</template>
<script>
import {
  mapGetters,
} from 'vuex'
import TransferPage from './TransferPage.vue'
import CardPayment from '../shared/CardPayment.vue'
import CCPaymentPage from './CCPaymentPage.vue'
import axios from 'axios'
import * as types from '../../store/types'

export default {
  name: "",
  data: () => ({
    tUser:{},
    type:'',
    invoice:{},
  }),
  methods: {
    findInvoice() {
      if (this.tUser.unpaidInvoice){
        axios.get('/rents.json?orderBy="tenantId"&equalTo="'+this.tUser.id+'"')
        .then(res=>{
          console.log(res);
          for (var inv in res.data) {
            if (res.data.hasOwnProperty(inv)) {
              this.invoice=res.data[inv]
              this.invoice['.key']=inv
            }
          }
          console.log('INVOICE:',this.invoice);
          this.invoice.tenant=this.tUser
          console.log(this.invoice);
        })
      }
    },
    // getPrime() {
    //   TPDirect.card.getPrime((result) => {
    //     console.log(result.card.prime)
    //
    //     alert(result.card.prime);
    //   })
    // }
  },
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    if (this.$route.query.id&& this.user.roleValue>20){
      axios.get('/users/'+this.$route.query.id+'.json')
      .then(res=>{

        this.tUser=res.data
        this.tUser.id=this.$route.query.id
        this.findInvoice()
      })
    } else {
      this.tUser=this.user
      this.findInvoice()
    }



  },
  // mounted() {
  //   //do something after mounting vue instance
  //   console.log(TPDirect.card);
  //   TPDirect.card.setup('#tappay-iframe')
  // },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  components: {
    CardPayment,
    TransferPage,
    CCPaymentPage,
  },


}
</script>
<style lang="scss" scoped>
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,q_auto:eco,w_1400/v1537776822/blog/work-outside2.jpg") center/cover no-repeat;
}
.fixedHeight{
  height:4em
}
.blog-title {
    margin: auto;
    padding-top: 15vh;
    margin-bottom: 10vh;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}
.careers div {
    margin: 1rem 0;
}
.fix-height{
  height:6rem;
}
#tappay-iframe {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  line-height: 1.21428571em;
  padding: .578571em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, .15);
  color: rgba(0, 0, 0, .87);
  border-radius: .28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  -webkit-transition: color .1s ease, border-color .1s ease;
  transition: color .1s ease, border-color .1s ease;
  width: 100%;
}

.card-hover:hover{
cursor:pointer;
text-decoration: underline;
}
.huge{
font-size:10em !important;
}


</style>
