<template>
<div id="">
  <v-text-field
    label="Cardholder Name"
    v-model="cardName"
    id="addCardName"
  ></v-text-field>
  <div id="example">
    <div class="" id="tappay-iframe"></div>
    <div class="trust pt-3" id="trust">
      <img src="@/assets/cc/visa.svg" height="25"/>
      <img src="@/assets/cc/mastercard.svg" height="25"/>
      <img src="@/assets/cc/jcb.svg" height="25"/>
    </div>
    <div class="trust-2 py-2">
      <v-layout  wrap class="green-text">
        <v-flex xs12 color="green">
          <span>
            <v-icon color="green">done</v-icon>
            All transmissions are secure
          </span>
        </v-flex>
        <v-flex xs12 >
          <span>
            <v-icon color="green">done</v-icon>
            We use SSL Encryption verification
          </span>
        </v-flex>
        <v-flex xs12>
          <span color="green">
            <v-icon color="green">done</v-icon>
            Your personal information and card details are encrypted and securely stored
          </span>

        </v-flex>
      </v-layout>
    </div>
    <div class="">
      <div class="">
        {{cardError}}
      </div>
      <v-btn color="primary" :disabled="sending" @click="getPrime()">Confirm</v-btn>
    </div>

  </div>
</div>
</template>
<script>
import {
  mapGetters,
} from 'vuex'
import axios from 'axios'
import * as types from '../../store/types'


export default {
  name: "",
  props: ['visit'],
  data: () => ({
    cardError:"",
    sending: false,
    cardName:"",
  }),
  methods: {
    getPrime() {
      this.cardError=""
      this.sending = true
      this.$emit("confirmBook", "")
      TPDirect.card.getPrime((result) => {
        // console.log('');
        try{
          // console.log('The prime:', result.card.prime)
          // console.log(this.user.phone);
          // alert(result.card.prime);
          // console.log('there is a phone');
          let info = {
            name: this.cardName,
            phone: this.user.phone,
            email: this.user.email,
            prime: result.card.prime,
          }
          // console.log("send the response, header:", config);
          // axios.post('http://localhost:5000/my-room-abroad/us-central1/addCard', info, {
          axios.post('https://us-central1-my-room-abroad.cloudfunctions.net/addCard', info, {
            headers: {
              'Content-Type': 'text/plain'
            }
          })
          .then(res => {
            console.log('Response data: ', res.data);
            if (res.data.status == 0) {
              this.sending = false
              this.$emit('cardOk')
              // console.log(res.data);
              axios.patch('users/' + this.user.id + '/card.json?auth=' + this.$store.state.auth.idToken, res.data.card_secret)
              // .then(res=>{
              //   console.log(res);
              // })
            } else {
              this.sending = false
              this.cardError="There is a problem with your card. Please check the card info"
              console.log(res.data.msg);
              this.$emit('cardError')
            }
          })

        }
        catch(err){
          console.log(err);
          this.sending=false
          this.$emit('cardError')
          this.cardError="Your card is invalid. Please use another card. We accept Visa, MasterCard and most of other Asian credit cards. Debit cards and American Express are not accepted."
        }

      })
    },
  },
  mounted() {
    //do something after mounting vue instance
    TPDirect.card.setup('#tappay-iframe')
    // console.log(TPDirect.card);
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    total(){
      return parseInt(this.invoice.price)+parseInt(this.invoice.utilities)+this.transferCosts
    },
    transferCosts(){
      if (this.invoice.currency=="TWD"){
        return 15
      } else {
        return (parseInt(this.invoice.price)+parseInt(this.invoice.utilities))*.028
      }
    },


  }
}
</script>
<style lang="scss" scoped>
.green-text{
  color:green;
}
#tappay-iframe {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0;
    outline: 0;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255, 255, 255, 0);
    line-height: 1.21428571em;
    padding: 0.578571em 1em;
    font-size: 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    color: rgba(0, 0, 0, .87);
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
    width: 30vw;
}
@media only screen and (max-width: 600px) {
    #tappay-iframe{
      width:85vw;
    }
}


</style>
