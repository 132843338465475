<template>
<div class="">
  <Navbar></Navbar>
  <v-layout  wrap justify-space-around>
    <v-flex xs12 sm10 md8 v-if="invoice.paidTS==undefined && loading!=true">
      <v-layout  wrap justify-space-around class="ma-4">
      <h2 class="headline">Payment Request on My Room Abroad</h2>

      <v-card width="100%">

        <v-layout  wrap justify-center class="ma-3 no-ma-sides">
          <v-flex xs9>
            <h2>{{$t("paymentPage.description")}}</h2>
            <br>
            <p style="white-space: pre-line;">{{invoice.comment}}</p>
            <p>Transaction Cost</p>
          </v-flex>
          <v-flex xs3 class="text-xs-right">
            <h2>{{$t("paymentPage.amount")}}</h2>
            <br>
            <p>{{invoice.price}} TWD</p>
            <p>{{transferCosts}} TWD</p>

          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout  wrap class="ma-3 no-ma-sides">
          <v-flex xs9 class='text-xs-right'>
            <h2>{{$t("paymentPage.totalToPay")}}</h2>
          </v-flex>
          <v-flex xs3 class='text-xs-right'>
            <h2>
              {{total}} TWD
            </h2>
          </v-flex>
        </v-layout>
        <v-layout  wrap>
          <v-spacer></v-spacer>
          <CardPayment :amount="total" :comment="invoice.comment" @success="successCheck" @fail="fail"></CardPayment>
        </v-layout>
        <v-layout v-if="working==true" wrap>
            <div class="">
              <span >We're working. Please wait a moment...</span>
            </div>
            <div class="">
              <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>

        </v-layout>
      </v-card>

      </v-layout>

    </v-flex>
    <v-flex xs12 sm10 md8 pt-5 pl-4 v-else-if="invoice.paidTS==undefined && loading==true">
      <h2 class="headline">Payment Request on My Room Abroad</h2>
      <Loading></Loading>

    </v-flex>
    <v-flex xs12 sm10 md8 pt-5 pl-4 v-else>
      <h2>Nothing to pay</h2>
    </v-flex>
  </v-layout>
  <v-dialog v-model="success" persistent max-width="290">
    <v-card>
      <v-card-title class="headline">Payment Successful</v-card-title>
      <v-card-text>Thanks! Your payment was processed succesfully.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primany" flat @click="success = false; $router.push('/')">ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import {
  mapGetters,
} from 'vuex'
import Loading from '../shared/Loading.vue'
import CardPayment from '../shared/CardPayment.vue'
import axios from 'axios'
import * as types from '../../store/types'
import firebase from '../../FirebaseApp'
import moment from 'moment'

export default {
  name: "",
  data: () => ({
    tUser:{},
    type:'',
    loading:true,
    success:false,
    working:false,
    // invoice:{},
  }),
  firebase() {
    return {
      invoice: {
        source:firebase.database().ref('otherPayments/'+this.$route.params.payId),
        asObject:true,
        readyCallback:function(){
          if (this.user.roleValue<80 && this.invoice.userId!=this.user.userId ){
            console.log('Not your invoice!');
            alert('Not your invoice!')
            this.$router.push('/')
          } else {
            this.loading=false
          }

        }
      }
    }
  },
  methods: {
    fail(){
      alert('Payment Failed. If the problem persists, please contact My Room Abroad.')
      AWSSES.emailing(this.user, 'TPaymentFailed')
    },
    successCheck(){
      this.working=true
      let payment={
        type:"CC",
        ts:moment().format('YYMMDD'),
        total:this.total,
        tenantId:this.invoice.userId,
        ref:"VISIT"+this.invoice.userName.substring(0,3)+moment().format('YYMMDD'),
        comment:this.invoice.comment,
      }
      axios.patch('/otherPayments/'+this.$route.params.payId+'.json?auth=' + this.$store.state.auth.idToken,{paidTS:moment().format('X'), ref:payment.ref})
      .then(()=>{
        this.working=false
        this.success=true

      })
      axios.post('payIn.json?auth='+this.$store.state.auth.idToken, payment)
    }
    // getPrime() {
    //   TPDirect.card.getPrime((result) => {
    //     console.log(result.card.prime)
    //
    //     alert(result.card.prime);
    //   })
    // }
  },
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    if (this.$route.query.id&& this.user.roleValue>20){
      axios.get('/users/'+this.$route.query.id+'.json')
      .then(res=>{
        this.tUser=res.data
      })
    }



  },
  // mounted() {
  //   //do something after mounting vue instance
  //   console.log(TPDirect.card);
  //   TPDirect.card.setup('#tappay-iframe')
  // },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    transferCosts(){
      return Math.ceil(parseInt(this.invoice.price)*.028)
    },
    total(){
      return parseInt(this.invoice.price)+this.transferCosts
    },
  },
  components: {
    CardPayment,
    Loading
  },


}
</script>
<style lang="scss" scoped>
</style>
