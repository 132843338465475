<template>
<div id="">
  <v-layout  wrap justify-center>
    <v-flex xs12 sm7 >
      <v-card>
        <v-img
        :src="thumbnailUrl"
        alt="room thumbnail"
        ></v-img>
        <v-card-title primary-title>
          <v-layout  wrap>
            <v-flex xs12>
              <h3 class="headline">{{room.general.title}}</h3>

            </v-flex>
          </v-layout>
        </v-card-title>

        <v-divider></v-divider>
        <v-layout  wrap justify-space-around>
          <v-flex xs6 sm4 md3 class="pa-4" v-for="(item, index) in tabs" :key="index">
            <v-card hover :href="item.link">
              <v-layout  align-center justify-center  wrap>
                <v-icon :href="item.link" class="pt-3 large" large>{{item.icon}}</v-icon>

              </v-layout>
              <v-card-title class="text-xs-center fixedHeight" justify-center primary-title>
                <v-layout   justify-center  wrap>
                  <v-list>

                  <h3 class="card-hover title" :href="item.link">
                    {{item.name}}
                  </h3>
                </v-list>
                </v-layout>
              </v-card-title>
              <v-card-actions>

                <v-layout  wrap justify-center>
                  <v-btn color="primary" flat :href="item.link" >go</v-btn>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-flex>
          <v-flex xs6 sm4 md3 class="pa-4" v-if="tUser.deposit==true">
            <v-card hover href="/mybooking/deposit">
              <v-layout  align-center justify-center  wrap>
                <v-icon href="/myBooking/deposit" class="pt-3 large" large>credit_card</v-icon>

              </v-layout>
              <v-card-title class="text-xs-center fixedHeight" justify-center primary-title>
                <v-layout   justify-center  wrap>
                  <v-list>

                  <h3 class="card-hover title" href="/myBooking/deposit">
                    Pay your deposit
                  </h3>
                </v-list>
                </v-layout>
              </v-card-title>
              <v-card-actions>

                <v-layout  wrap justify-center>
                  <v-btn color="primary" flat href="/mybooking/deposit" >go</v-btn>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <div class="">
          <v-btn large href="/ratings" color="primary">Rate Your Room</v-btn>
        </div>
        <div class="pa-2">
          <span>{{$t("generalInfo.roomNumber")}}: {{order.fullRoomNumber}}</span>
        </div>
        <div class="pa-2" v-if="order.landlord.name!=undefined">
          {{$t("userRequest.llName")}}{{order.landlord.name}}
        </div>
        <div class="pa-2" v-else>
          {{$t("userRequest.llName")}}{{order.landlord.userName}}
        </div>
      <div class="pa-2">
        {{$t("userRequest.llEmail")}}<a :href="'mailto:'+order.landlord.email+'?subject=Nice%20to%20meet%20you'" target="_top">{{order.landlord.email}}</a>

      </div>
        <v-btn color="primary" flat :href="'https://maps.google.com/?q='+order.location.lat+','+order.location.lng" target="_blank">{{$t('userRequest.directions')}}</v-btn>

        <v-divider></v-divider>
        <v-card-text>
          <v-layout  wrap align-center>
            <v-flex xs12 class="title">
              {{$t("userRequest.contractDates")}}
            </v-flex>
            <v-flex xs5 class="lighten-1">
              {{$t("userRequest.mid")}}<br>
              <span class="subheading">{{order.dates.mid | dateFormat}}</span>
            </v-flex>
            <v-flex xs2>
              <v-icon>arrow_forward</v-icon>
            </v-flex>
            <v-flex xs5 class="text-xs-right">
              {{$t("userRequest.mod")}}<br>
              <span class="subheading">{{order.dates.mod | dateFormat}}</span>
            </v-flex>
            <v-flex xs1>

            </v-flex>
          </v-layout>
          <v-layout  wrap align-center>
            <v-flex xs12 class="title">
              {{$t("requestsInbox.realDates")}}
            </v-flex>
            <v-flex xs5 class="lighten-1">
              {{$t("userRequest.mid")}}<br>
              <span class="subheading" v-if="!editDates">{{order.realDates.mid | dateFormat}}</span>
                <v-menu
                 ref="midMenu"
                 :close-on-content-click="false"
                 v-model="midMenu"
                 :return-value.sync="mid"
                 lazy
                 transition="scale-transition"
                 min-width="290px"
                 v-else
               >
                 <v-text-field
                   slot="activator"
                   v-model="mid"
                   :label="$t('userRequest.mid')"
                   prepend-icon="event"
                   readonly
                   clearable
                 ></v-text-field>
                 <v-date-picker  :locale="$vuetify.lang.current" :min="minMid" :max="maxMod" v-model="mid"  @input="$refs.midMenu.save(mid)"></v-date-picker>
               </v-menu>
            </v-flex>
            <v-flex xs2>
              <v-icon>arrow_forward</v-icon>
            </v-flex>
            <v-flex xs5 class="text-xs-right">
              {{$t("userRequest.mod")}}<br>
              <span class="subheading" v-if="!editDates" >{{order.realDates.mod | dateFormat}}</span>
              <v-menu
               ref="modMenu"
               :close-on-content-click="false"
               v-model="modMenu"
               :return-value.sync="mod"
               lazy
               transition="scale-transition"
               min-width="290px"
               v-else
             >
               <v-text-field
                 slot="activator"
                 v-model="mod"
                 :label="$t('userRequest.mod')"
                 prepend-icon="event"
                 readonly
                 clearable
               ></v-text-field>
               <v-date-picker  :locale="$vuetify.lang.current" :min="minMod" :max="maxMod" v-model="mod" @input="$refs.modMenu.save(mod)"></v-date-picker>
             </v-menu>
            </v-flex>
            <v-flex xs1>

            </v-flex>
          </v-layout>
          <v-layout  wrap justify-ends>
            <a @click="editDates=true" v-if="!editDates">{{$t("landlordDashboard.edit")}}</a>
            <v-btn color="primary" @click="saveRealDates" v-else>{{$t("profile.save")}}</v-btn>
          </v-layout>
          <v-divider></v-divider>
          <div class="pa-2">

          <v-layout  wrap class="pt-2">
            <v-flex xs6>
              {{$t("billing.rent")}}
            </v-flex>
            <v-flex xs6 class="" >
              <span :data-value="parseInt(order.price)" id="MyBookingPrice">{{order.price}} NT$</span>
            </v-flex>
          </v-layout>
          <v-layout  wrap>
            <v-flex xs6>
              {{$t("billing.utilities")}}
            </v-flex>
            <v-flex xs6>
              {{order.listUtil.name}} <span v-if="order.listUtil.name=='Fixed monthly amount'">({{order.listUtil.amount}})</span>
              <div class="" v-if="order.listUtil.comment!=''">
                {{order.listUtil.comment}}
              </div>
            </v-flex>
          </v-layout>
          <v-layout  wrap align-center>
            <span>Automatic rent payment: <span v-if="order.reccRent==true"> Active</span><span v-else>  Disabled</span> </span>
            <v-btn color="red" flat dark @click="changeReccRent(false)" v-if="order.reccRent==true">Deactivate</v-btn>

            <div class="" v-else>
              <v-btn color="primary" flat dark @click="changeReccRent(true)" >Activate</v-btn>
              <span>  (You'll need to save your card during your next payment)</span>
            </div>
            <!-- <v-btn color="red" flat dark @click="changeReccRent(false)" v-if="user.roleValue>80">Deactivate</v-btn> -->
            <v-btn color="primary" flat @click="showUpdateCard=true" v-if="order.reccRent==true">Change card</v-btn>
            <AddCard @cardOk="updateCard" @cardError="error" v-if="showUpdateCard"></AddCard>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12>

              <AllPayments :realMid="order.realDates.mid" :mid="order.dates.mid" :mod="order.dates.mod" :price="order.price" :utilities="order.utilities" :listUtil="order.listUtil"></AllPayments>
            </v-flex>

          </v-layout>

        </div>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
  <v-snackbar
    v-model="showSuccess"
    color="primary"
  >
    Card updated
    <v-btn flat @click.native="showSuccess = false">Close</v-btn>
  </v-snackbar>

  <v-snackbar
    v-model="showError"
    color="error"
  >
    Card is not working. Please Don't try more than 3 times with the same card.
    <v-btn flat @click.native="showError = false">Close</v-btn>
  </v-snackbar>

</div>
</template>
<script>
import {
  mapGetters,
} from 'vuex'
import axios from 'axios'
import * as types from '../../store/types'
import moment from 'moment'
import AddCard from '../shared/AddCard.vue'
import config from '../../config'
import AllPayments from './AllPayments.vue'
export default {
  name: "",
  data: () => ({
    showSuccess:false,
    showError:false,
    showUpdateCard:false,
    editDates:false,
    midMenu:false,
    tUser:{},
    modMenu:false,
    orderId:"",
    order:{
      fullRoomNumber:"",
      landlord:{},
      location:{},
      listUtil:{},
      dates:{},
      reccRent:true,
      realDates:{},
    },
    room:{
      general:{
      }
    },
    thumbnailUrl:'',
    tabs:[
      {
        icon:"attach_money",
        name:"Pay your rent",
        link:"/mybooking/rent"
      },
      {
        icon:"build",
        name:"Check/Submit a problem",
        link:"/mybooking/issues"
      },
      {
        icon:"receipt",
        name:"Review past payments",
        link:"/mybooking/payments"
      },

    ]
  }),
  methods: {
    date(date){
      return moment(date).format("L")
    },
    changeReccRent(result){
      this.order.reccRent=result
      axios.patch('completedOrders/'+this.orderId+'.json?auth='+this.$store.state.auth.idToken,{reccRent:result})
      let tId=""
      axios.get('rents.json?orderBy="tenantId"&&equalTo="'+this.tUser.id+'"')
      .then(res=>{
        // console.log(res.data);
        // console.log(Object.keys(res.data).length);
        if (Object.keys(res.data).length>0){
          for (var i = 0; i < Object.keys(res.data).length; i++) {
            tId=Object.keys(res.data)[i]
            axios.patch('rents/'+tId+'.json?auth='+this.$store.state.auth.idToken,{reccRent:result})
          }
        }
      })
      .catch(err=>{
        console.log(err);
      })
      axios.get('transfTBM.json?orderBy="tenantId"&&equalTo="'+this.tUser.id+'"')
      .then(res=>{
        if (Object.keys(res.data).length>0){
          for (var i = 0; i < Object.keys(res.data).length; i++) {
            tId=Object.keys(res.data)[i]
            axios.patch('transfTBM/'+tId+'.json?auth='+this.$store.state.auth.idToken,{reccRent:result})
          }
        }
      })
      .catch(err=>{
        console.log(err);
      })
      axios.patch('completedOrders/'+this.orderId+'.json?auth='+this.$store.state.auth.idToken,{reccRent:false})
      // console.log('remove recc rentlo');
    },
    updateCard(){
      this.showUpdateCard=false
      this.showSuccess=true
    },
    error(){
      this.showError=true
    },
    saveRealDates(){
      axios.patch('completedOrders/'+this.orderId+'/realDates.json?auth='+this.$store.state.auth.idToken,this.order.realDates)
      let db=""
      switch (this.order.type.toLowerCase()) {
        case 'room':
          db='rooms/'
          break;
        case 'studio':
          db='studios/'
          break;
        case 'apartment':
          db='wholeApts/'
          break
        default:
          return ""
      }
      axios.patch(db+this.order.listingId+'/general/nextAvailability.json?auth=',this.order.realDates.mod)
    },
    getListing(req) {
      switch (req.type.toLowerCase()) {
        case 'room':
          axios.get('rooms/'+req.listingId+'.json')
          .then(res=>{
            this.room=res.data
            this.setThumbnail(this.room)
            axios.get('apartments/'+res.data.apartmentId+'.json')
            .then(res=>{
              this.apartment=res.data
            })
          })
          break;
        case 'apartment':
          axios.get('rooms/'+req.listingId+'.json')
          .then(res=>{
            this.room=res.data
            this.apartment=res.data
            this.setThumbnail(this.room)
          })
          break;
        case 'studio':
          axios.get('studios/'+req.listingId+'.json')
          .then(res=>{
            this.room=res.data
            this.apartment=res.data
            this.setThumbnail(this.room)
          })
          break;
        default:
          this.setThumbnail()

        }

    },
    loadBookings(){
      axios.get('bookings/'+this.tUser.bookingRequestOut+'.json')
      .then(res=>{
        this.order=res.data
        dataLayer.push({
          orderId:this.orderId,
          listingId:this.order.listingId,
          price:parseInt(this.order.price),
          margin:parseInt(this.order.price)*.2,
          'event':'CRLoaded'
        })
        this.getListing(res.data)
      })
    },
    afterUserLoad(){
      console.log(this.tUser.bookingRequestOut);
      axios.get('bookingRequests/'+this.tUser.bookingRequestOut+'/orderId.json')
      .then(res=>{
        this.orderId=res.data
        console.log(res.data);
        if (!res.data){
          this.loadBookings()
          return
        }
        axios.get('completedOrders/'+res.data+'.json')
        .then(res=>{
          this.order=res.data
          dataLayer.push({
            orderId:this.orderId,
            listingId:this.order.listingId,
            price:parseInt(this.order.price),
            margin:parseInt(this.order.price)*.2,
            'event':'CRLoaded'
          })
          this.getListing(res.data)
        })
      })

    },
    setThumbnail(room){
      this.thumbnailUrl=config.PHOTOURL+room.visual.photos[0].id
    },
  },
  computed:{
    ...mapGetters({
      user: types.GET_USER
    }),
    minMid(){
      return moment(this.order.dates.mid).format('YYYY-MM-DD')
    },
    maxMod(){
      return moment(this.order.dates.mod).format('YYYY-MM-DD')
    },
    mid:{
      get(){
        return moment(this.order.realDates.mid).format('YYYY-MM-DD')
      },
      set(val){
        this.order.realDates.mid=moment(val)
      }
    },
    mod:{
      get(){
        return moment(this.order.realDates.mod).format('YYYY-MM-DD')
      },
      set(val){
        this.order.realDates.mod=moment(val)
      }
    }
  },
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    console.log(this.$route.query.id&& this.user.roleValue>80);
    if  (this.$route.query.id!=undefined){

      console.log('get the id',this.$route.query.id);
      axios.get('users/'+this.$route.query.id+'.json')
      .then(res=>{
        console.log(res.data);
        this.tUser=res.data
        this.tUser.id=this.$route.query.id
        this.afterUserLoad()
      })
    } else {
      this.tUser=this.user
      this.afterUserLoad()
    }

  },
  filters: {
    dateFormat: function(t) {
      return moment(t).format('ll')
    },
  },
  components: {
    AddCard,
    AllPayments
  }
}
</script>
<style lang="scss" scoped>
.fixedHeight{
  height:4em;
}
.card-hover:hover{
  cursor:pointer;
  text-decoration: underline;
}
.huge{
  font-size:10em !important;
}

</style>
