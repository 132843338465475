<template>
  <div id="">
    <v-flex xs12>
      <span class="headline">List of all Payments*:</span>
    </v-flex>
    <v-layout class="boxed pa-2 mx-2" wrap>
      <v-flex xs10>
        <v-layout>
          <v-flex xs6>
            Upon Acceptance
          </v-flex>
          <v-flex xs6>
            {{reservationFee}} TW$ (First Month rent + Service Fee + Transaction Costs)
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs6>
            Upon Arrival ({{realMid | dates }})
          </v-flex>
          <v-flex xs6>
            Deposit - (Paid directly to the landlord unless agreed otherwise.)
          </v-flex>
        </v-layout>
        <div class="" v-if="isOddDates!=true">

        <v-layout v-if="numberOfMonths>1" v-for="x in (numberOfMonths-1)" :key="x">
          <v-flex xs6>
            {{paymentDate | payMonth(x)}}
          </v-flex>
          <v-flex xs6>
            {{price}} TW$ (Rent<span v-if="listUtil.name!='Included in rent'"> + utilities</span>)
          </v-flex>
        </v-layout>
      </div>
        <!-- WEIRD DATES!! -->
        <div class="" v-else>
          <!-- {{numberOfMonths}} -->
          <!-- First month to Calibrate -->
          <v-layout wrap v-if="numberOfMonths>2">
            <v-flex xs6 >
              {{paymentDate | payMonth(1)}}
            </v-flex>
            <v-flex xs6>
              {{firstPart}} TW$ (First Rent Payment to end of the month)
            </v-flex>
          </v-layout>
          <!-- All other months -->
          <div class="" v-if="numberOfMonths>3">

            <v-layout v-for="x in (numberOfMonths-3)" :key="x">
              <v-flex xs6>
                {{endOfMonth | payMonth(x)}}
              </v-flex>
              <v-flex xs6>
                {{price}} TW$ (Rent)
              </v-flex>
            </v-layout>
          </div>

          <!-- Last month to Event out -->
          <v-layout wrap v-if="numberOfMonths>1">
            <v-flex xs6 >
              {{endOfMonth | payMonth(numberOfMonths-2)}}
            </v-flex>
            <v-flex xs6>
              {{lastPart}} TW$ (Last Payment to end of contract)
            </v-flex>
          </v-layout>
        </div>

      </v-flex>
    </v-layout>
    <small>*These prices are computed based on the rental prices and your dates and do not include potential additional utilities. These numbers are for information only and may slightly vary due to utilities</small>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: "",
  props:['realMid','mid','mod','price','utilities','listUtil'],
  data: () => ({

  }),
  computed:{
    reservationFee(){
      return 'See invoice'
    },
    isOddDates(){
      let t=false
      if (moment(this.mod).add(1,'days').format('DD')==moment(this.mid).format("DD")){
        t=false
      } else {
        t=true
      }
      return t
    },
    numberOfMonths(){
      console.log(moment(this.mod).add(1,'days').diff(moment(this.mid),'months',true));
      let t= Math.ceil(moment(this.mod).add(1,'days').diff(moment(this.mid),'months',true))
      if (this.isOddDates && moment(this.mod).date() < moment(this.mid).date() ){
        t=t+1
      }
      console.log(t, "Number of months");
      return t
    },
    paymentDate(){
      console.log(moment(this.mid).date(), 'date', 10<moment(this.mid).date() && moment(this.mid).date()<=25);
      if (10<moment(this.mid).date()&& moment(this.mid).date()<=25){
        return(moment(this.mid).add(1,'month').date(5))
      } else {
        if (25<moment(this.mid).date()){
          return moment(this.mid).add(1,'month').date(25)
        } else {
          return moment(this.mid).date(25)
        }
      }
    },
    firstPart(){
      if (this.isOddDates){
        return Math.ceil((moment(this.mid).add(1,'months').endOf('month').diff(moment(this.mid).add(1,'months'),'days',true))/moment(this.mid).add(1,'months').daysInMonth()*this.price)
      } else {
        return 0
      }
    },
    lastPart(){
      if (this.isOddDates){
        return Math.ceil((moment(this.mod).date()/moment(this.mod).daysInMonth())*this.price)
      } else {
        return 0
      }
    },
    endOfMonth(){
      return moment(this.mid).add(1,'month').date(25)
    }
  },
  filters:{
    dates(t){
      if (t!=undefined){
        return moment(t).format('LL')
      }
    },
    payMonth(t,x){
      return moment(t).add(x-1, 'months').format('LL')
    }

  },


}
</script>
<style lang="scss" scoped>
.boxed{
  border-radius:5px;
  border: solid thin rgba(0,0,0,.38)
}

</style>
