<template>
<div id="">
  <v-layout  wrap justify-space-around class="ma-3">

  <v-card width="100%">

  <v-container grid-list-xs,sm,md,lg,xl>
    <v-layout  wrap justify-center class="ma-3 no-ma-sides">
      <v-flex xs9>
        <h2>{{$t("paymentPage.description")}}</h2>
        <br>
        <p style="white-space: pre-line;">{{invoice.comment}}</p>
        <p v-if="parseInt(invoice.utilities)>0">{{$t("billing.utilities")}}</p>
        <p v-if="parseInt(invoice.utilities)<0">Discount</p>

        <p>Transaction Cost</p>
        <p v-if="daysLate !=0">Late Fees: {{daysLate}} days (100 TWD/day)</p>
      </v-flex>
      <v-flex xs3 class="text-xs-right">
        <h2>{{$t("paymentPage.amount")}}</h2>
        <br>
        <p>{{invoice.price}} TWD</p>
        <p v-if="parseInt(invoice.utilities)>0">{{invoice.utilities}} TWD</p>
        <p>{{transferCosts}} TWD</p>
        <p v-if="daysLate!=0">{{lateFees}} TWD</p>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
    <v-layout  wrap class="ma-3 no-ma-sides">
      <v-flex xs9 class='text-xs-right'>
        <h2>{{$t("paymentPage.totalToPay")}}</h2>
      </v-flex>
      <v-flex xs3 class='text-xs-right'>
        <h2>
          {{total}} TWD
        </h2>
      </v-flex>
    </v-layout>
    <v-layout  wrap>
      <v-spacer></v-spacer>
      <CardPayment :amount="total" :comment="invoice.comment" @success="successCheck" @fail="fail"></CardPayment>
    </v-layout>
    <v-layout v-if="working==true" wrap>
        <div class="">
          <span >Updating your profile now. Please wait a moment...</span>
        </div>
        <div class="">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>

    </v-layout>
  </v-container>
  </v-card>

  </v-layout>
  <v-dialog v-model="success" persistent max-width="290">
    <v-card>
      <v-card-title class="headline">Payment Successful</v-card-title>
      <v-card-text>Thanks! Your payment was processed succesfully.
        <br>You will soon be redirected to your room page.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primany" flat @click="success = false">ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import Loading from '../shared/Loading.vue'
import axios from 'axios'
import moment from 'moment'
import CardPayment from '../shared/CardPayment.vue'
import AWSSES from '../../aws-ses'


export default {
  name: "",
  props: ['invoice', 'user'],
  data: () => ({
    admin:{
      email:'hello@myroomabroad.com',
      userName:'Sexy'
    },
    success:false,
    working:false
  }),
  methods: {
    setPaid(rent){
      //add to transfersTBM
      this.working=true
      let t={
        brId:rent.brId,
        comment:rent.comment,
        crId:rent.crId,
        currency:'TWD',
        dates:rent.dates,
        landlordId:rent.landlordId,
        listUtil:rent.listUtil,
        listingId:rent.listingId,
        moneyInTS:new Date(),
        reccRent:rent.reccRent,
        nextRent:rent.nextRent,
        price:rent.price,
        llPrice:rent.llPrice,
        realDates:rent.realDates,
        roomNumber:rent.roomNumber,
        tenantId:rent.tenantId,
        type:rent.type,
        perc: rent.perc,
        utilities:rent.utilities,
        lastRent:rent.month
      }
      let userEdit={}
      if (rent.deposit==true) {
        t.deposit=true
        userEdit={
          deposit:false,
          depositPaid:true
        }
        axios.get('users/'+rent.landlordId+'.json')
        .then(res=>{
          if (res.data.roleValue>19){
            let info={
              room:t.roomNumber
            }
            AWSSES.emailing(res.data, info, 'LLDepositPaid')
          }
        })
        .catch(err=>{
          console.log(err);
        })
      } else if (rent.changeRoom==true){
        t.changeRoom=true
        t.price=parseInt(t.price)/1.2
        userEdit={
          deposit:false
        }
      } else {
        userEdit={
          unpaidInvoice:false
        }
      }
      console.log('POST -- ' ,t);
      axios.post('transfTBM.json?auth='+ this.$store.state.auth.idToken,t)
      .then(()=>{
        console.log('DELETE -- rents/'+rent['.key']+'.json?auth=');
        //remove from list
        if (rent['.key']!=undefined && rent['.key']!=''){
          axios.delete('rents/'+rent['.key']+'.json?auth='+ this.$store.state.auth.idToken)
          .then(()=>{
            this.working=false
            this.success=true
          })
        }
      })
      // let index = this.rentsDB.findIndex(item => item['.key']==rent['.key']);
      // this.rentsDB.splice(index, 1)
      console.log('PATCH -- users/'+rent.tenantId+'.json?auth=',userEdit);
      axios.patch('users/'+rent.tenantId+'.json?auth='+ this.$store.state.auth.idToken,userEdit)
      // create a Received payment.
      if (rent.tenant){
        let ref=moment().format('YYMMDD')+rent.tenant.name.charAt(0)+rent.tenant.name.split(" ").pop().charAt(0)
      } else {
        let ref=moment().format('YYMMDD')+rent['.key'].charAt(1)+rent['.key'].charAt(2)
      }
      let details={
        total:this.total,
        ref:ref,
        listingId:rent.listingId,
        tenantId:rent.tenantId,
        type:"CC",
        comment:rent.comment,
        ts:moment().format('YYMMDD')
      }
      axios.post('payIn.json?auth='+this.$store.state.auth.idToken,details)
      .then(()=>{
        this.$router.push('/myBooking')
      })
    },

    successCheck(){
      this.setPaid(this.invoice)
      AWSSES.emailing(this.user,'TRentReceived')
      // AWSSES.emailing(this.admin, 'APaymentReceived')
    },
    fail(){
      alert('Payment Failed')
      // AWSSES.emailing(this.admin, 'APaymentFailed')
      AWSSES.emailing(this.user, 'TPaymentFailed')
    }
  },
  computed: {
    daysLate(){
      if (this.invoice.noLateFees!=true && moment(this.invoice.dueDate).isValid() && moment(this.invoice.dueDate).isBefore(moment()) && this.invoice.deposit!=true){
        return moment().diff(moment(this.invoice.dueDate),'days')
      } else {
        return 0
      }
    },
    total() {
      console.log(parseInt(this.invoice.utilities));
      if (this.invoice.utilities==undefined){
        this.invoice.utilities=0
      }
      let add=this.lateFees+parseInt(this.invoice.utilities)+this.transferCosts
      if (this.invoice.nextRent!=undefined){
        return parseInt(this.invoice.nextRent)+ add
      } else {
        return parseInt(this.invoice.price) + add
      }
    },
    lateFees(){
      return this.daysLate*100
    },
    transferCosts() {
      if (this.invoice.utilities==undefined){
        this.invoice.utilities=0
      }
      if (this.invoice.nextRent!=undefined){
          return Math.ceil((parseInt(this.invoice.nextRent) + parseInt(this.invoice.utilities)) * .028)
      } else {
          return Math.ceil((parseInt(this.invoice.price) + parseInt(this.invoice.utilities)) * .028)
      }
    },

  },
  created() {
    //do something after creating vue instance
    console.log('do the created');


  },
  components: {
    Loading,
    CardPayment,
  }
}
</script>
<style lang="scss" scoped>

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #00bfff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
</style>
