<template>
<div>
  <v-card>
   <v-card-title>
     Past Payments
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="cPayments"
     :search="search"
   >
     <template slot="items" slot-scope="props">
       <tr @click="props.expanded = !props.expanded">
         <td class="">{{ props.item.ts | dateFormat}}</td>
         <td class="">{{ props.item.ref }}</td>
         <td class="" v-if="props.item.type=='CC'">{{Math.floor(props.item.total/1.028)}} TW$ (+{{parseInt(props.item.total)-Math.floor(props.item.total/1.028)}} TW$ transfer costs)</td>
         <td class="" v-else-if="props.item.type=='ACC'">{{props.item.total}} TW$  (+{{Math.ceil(Math.ceil(props.item.total*.028))}} TW$ transfer costs)</td>
         <td class="" v-else>{{props.item.total}} TW$</td>

         <td class="">{{ props.item.comment }}</td>
       </tr>
     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
</div>
</template>

<script>
import firebase from '../../FirebaseApp'
import moment from 'moment'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'
import axios from 'axios'

export default {
  name: 'Payments',
  data: () => ({
    headers: [
      { text: 'Date', value: 'ts' },
      { text: 'Reference', value: 'ref' },
      { text: 'Total transferred', value: 'total' },
      { text: 'Transfer comment', value: 'comment' },
    ],
    cPayments:[],
    search: null,
    searched: [],
  }),
  firebase() {
    return {
      transfs: {
        source:firebase.database().ref('payIn').orderByChild("tenantId").equalTo(this.user.id),

      }
    }
  },
  methods: {
    // fxTotal(item){
    //   let fxTotal=Math.ceil(Number(item.total)*Number(item.fx))
    //   return fxTotal
    // }
  },
  filters: {
    dateFormat: function(t) {
      if (t){
        return moment(t,'YYMMDD').format('ll')
      } else {
        return "Nothing to show"
      }
    },
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created() {
    this.cPayments=this.transfs
    if (this.$route.query.id!=undefined){
      this.cPayments=[]
      axios.get('payIn.json?orderBy="tenantId"&equalTo="'+this.$route.query.id+'"')
      .then(res=>{
        for (var id in res.data) {
          if (res.data.hasOwnProperty(id)) {
            res.data[id]['.key']=id
            this.cPayments.push(res.data[id])
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
</style>
